<template>
  <tr>
    <td class="nowrap-column">
      <strong :class="seo_item.is_static_page ? 'text-muted text-underline' : ''">
        {{ seo_item.item_type }}
      </strong>
      <span v-if="seo_item.item_id && !seo_item.is_static_page">
        , ID <strong>{{ seo_item.item_id }}</strong>
      </span>
    </td>
    <td style="min-width: 180px;" v-bind:class="!seo_item.title ? 'text-muted' : ''">
      {{ seo_item.title ? seo_item.title : "—" }}
    </td>
    <td style="min-width: 250px;" v-bind:class="!seo_item.description ? 'text-muted' : ''">
      {{ seo_item.description ? seo_item.description : "—" }}
    </td>
    <td style="min-width: 180px;" v-bind:class="!seo_item.h1_title ? 'text-muted' : ''">
      {{ seo_item.h1_title ? seo_item.h1_title : "—" }}
    </td>
    <td class="nowrap-column">
      {{ seo_item.updated_date }}
      <small v-if="seo_item.editor">
        <br />
        Обновил <strong>{{ seo_item.editor.name }}</strong>
      </small>
    </td>
    <td class="nowrap-column">
      <router-link :to="{
                            name: 'seo_item.edit',
                            params: {
                                seoItemId: seo_item.id
                            }
                         }"
                   class="btn pull-right"
                   v-bind:class="(seo_item.title && seo_item.description && seo_item.keywords) ? 'btn-success' : 'btn-primary'"
                   style="margin: 3px 2px 2px 3px"
                   title="Редактировать">
        <i class="fa fa-edit"></i>
      </router-link>
    </td>
  </tr>
</template>

<script>
export default {
  name: "item",

  props: {
    seo_item: {
      type: Object,
      default() {
        return this.$props.$_PaginationObject;
      },
    },
  },

  methods: {},
};
</script>

<style scoped>

</style>
